import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { getImage } from 'gatsby-plugin-image'
import config from '../../config.json'
import { toggleCollapse } from '../scripts/scripts'

import '../scss/pages/ranking.scss'
import Posts from '../components/Posts/Posts'
import InfoBox from '../components/InfoBox/InfoBox'
import Stars from '../components/Stars/Stars'
import PrimaryButton from '../components/PrimaryButton/PrimaryButton'

const InsuranceRankingPage = () => {
    const data = useStaticQuery(query);
    const seo = data.wpPage.seo;
    const ranking = data.allWpPost.edges;
    
    let firstPosition = ranking.filter(function (item) {
        return Boolean(item.node.ranking.najczesciejWybieranaOferta);
    });

    let otherPositions = ranking.filter(function (item) {
        return !Boolean(item.node.ranking.najczesciejWybieranaOferta);
    })

    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/ranking-ubezpieczen/" />
            <div className="page-top-section">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="small-header text-center mb-0 text-white">Porównaj polisy</p>
                            <h1 className="header-h1 text-center mt-15 mb-30 text-white">Ranking ubezpieczeń na życie do kredytu hipotecznego</h1>
                            <p className="paragraph text-center text-white font-light mb-0">Zapoznaj się z aktualnym rankingiem ubezpieczeń na życie do kredytu hipotecznego. Wybieraj spośród 10 ofert największych towarzystw ubezpieczeniowych. Sprawdź cenę, zakres ochrony oraz wady i zalety produktów.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ranking section-padding">
                <div className="container">
                    <div className="row">
                        {firstPosition.map((item, index) => {
                            let image = null;
                            if(Boolean(item.node.featuredImage)) {
                                image = getImage(item.node.featuredImage.node.localFile);
                            }
                            
                            return <div className="col-md-12 mb-30">
                                <div className="ranking-row first-ranking-row">
                                    <div className="best-offer">Najpopularniejsza oferta</div>
                                    <h2 className="product-name">{item.node.ranking.nazwaProduktu}</h2>
                                    <div className="position">★</div>
                                    <div className="d-flex mobile-main-container">
                                        {Boolean(image) ? (
                                            <div className="logo" style={{ backgroundImage: `url(${image.images.fallback.src})` }}></div>
                                        ) : (
                                            <div className="logo"></div>
                                        )}
                                        <div className="information">
                                            <div className="d-flex align-items-center">
                                                <div className="single-box">Wysokość kredytu<br /><span>{item.node.ranking.wysokoscKredytu}</span></div>
                                                <div className="single-box">Ilość rat<br /><span>{item.node.ranking.iloscRat}</span></div>
                                                <div className="single-box">Wysokość raty<br /><span>{item.node.ranking.wysokoscRaty}</span></div>
                                                <div className="single-box mr-0">Składka miesięczna<br /><span class="price">{item.node.ranking.miesiecznaOplata}</span></div>
                                            </div>
                                            <div className="row">
                                                <div className="advantages mt-15">
                                                    <p className="title mb-2">Dodatkowe atuty:</p>
                                                    {item.node.ranking.dodatkoweAtuty.split(/\r?\n/).map((item) => {
                                                        return <span className="advantage">{item}</span>
                                                    })}
                                                </div>
                                                <div className="row-footer col-12">
                                                    <div class="link-group">
                                                        {item.node.content && (
                                                            <a className="details" href={`/ranking-ubezpieczen/${item.node.slug}/`} title={item.node.title}>Poznaj szczegóły</a>
                                                        )}
                                                        <PrimaryButton link={config.CALC_LINK} title="Porównaj oferty" icon="unbalanced-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mark">
                                        <Stars rating="10" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        })}

                        {otherPositions.map((item, index) => {
                            let image = null;
                            if(Boolean(item.node.featuredImage)) {
                                image = getImage(item.node.featuredImage.node.localFile);
                            }
                            
                            return <div className="col-md-12 mb-30">
                                <div className="ranking-row">
                                    <div className="position">{firstPosition.length++}</div>
                                    <h2 className="product-name">{item.node.ranking.nazwaProduktu}</h2>
                                    <div className="d-flex mobile-main-container">
                                        {Boolean(image) ? (
                                            <div className="logo" style={{ backgroundImage: `url(${image.images.fallback.src})` }}></div>
                                        ) : (
                                            <div className="logo"></div>
                                        )}
                                        <div className="information">
                                            <div className="d-flex align-items-center">
                                                <div className="single-box">Wysokość kredytu<br /><span>{item.node.ranking.wysokoscKredytu}</span></div>
                                                <div className="single-box">Ilość rat<br /><span>{item.node.ranking.iloscRat}</span></div>
                                                <div className="single-box">Wysokość raty<br /><span>{item.node.ranking.wysokoscRaty}</span></div>
                                                <div className="single-box mr-0">Składka miesięczna<br /><span class="price">{item.node.ranking.miesiecznaOplata}</span></div>
                                            </div>
                                            <div className="row">
                                                <div className="advantages mt-15">
                                                    <p className="title mb-2">Dodatkowe atuty:</p>
                                                    {item.node.ranking.dodatkoweAtuty.split(/\r?\n/).map((item) => {
                                                        if(Boolean(item)){
                                                            return <span className="advantage">{item}</span>
                                                        }
                                                    })}
                                                </div>
                                                <div className="row-footer">
                                                    <div class="link-group">
                                                        {item.node.content && (
                                                            <a className="details" href={`/ranking-ubezpieczen/${item.node.slug}/`} title={item.node.title}>Poznaj szczegóły</a>
                                                        )}
                                                        <PrimaryButton link={config.CALC_LINK} title="Porównaj oferty" icon="unbalanced-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mark">
                                        <div className="mark-value">{item.node.ranking.ocena}</div>
                                        <Stars rating={item.node.ranking.ocena} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <InfoBox />
            </div>
            <Posts />
        </Layout>
    )
}

export default InsuranceRankingPage

const query = graphql`
{
    wpPage(slug: {eq: "ranking-ubezpieczen"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Ranking"}}}}}
      sort: {fields: ranking___ocena, order: DESC}
    ) {
      edges {
        node {
          title
          content
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          ranking {
            dodatkoweAtuty
            iloscRat
            miesiecznaOplata
            naglowek
            nazwaProduktu
            ocena
            wysokoscKredytu
            wysokoscRaty
            najczesciejWybieranaOferta
          }
        }
      }
    }
}
`
